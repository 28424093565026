<template>
  <v-container fluid class="pa-6">
    <!-- toolbar -->
    <v-row>
      <v-btn
        dark
        color="primary"
        class="mt-1 mr-4"
        @click="openReportCreator(false)"
      >
        <v-icon small class="mr-2">mdi mdi-plus</v-icon>
        {{ $t("quick_report") }}
      </v-btn>
      <v-btn
        dark
        color="#ebac00"
        class="mt-1 mr-4"
        @click="openReportScheduler()"
      >
        <v-icon small class="mr-2">mdi mdi-clock</v-icon>
        {{ $t("schedule") }}
      </v-btn>
      <v-btn dark color="primary" class="mt-1 mr-4" @click="refreshTables">
        <v-icon small class="mr-2">mdi mdi-sync</v-icon>
        {{ $t("refresh") }}
      </v-btn>
      <v-spacer />
    </v-row>
    <!-- table -->
    <v-row>
      <GsReportTable ref="reportTable" :userLang="userLang" />
    </v-row>
    <!-- create report -->
    <v-dialog
      v-if="dialogReportCreatorVisible"
      :value="true"
      max-width="880px"
      max-height="100%"
      persistent
    >
      <GsReportCreator
        ref="reportCreatorComponent"
        @close="closeReportCreator"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import { memory } from "ngt-frontend-core";
import GsReportTable from "@/components/GsReportTable";
import GsReportCreator from "@/components/GsReportCreator";

export default {
  name: "ReportsList",
  components: {
    GsReportTable,
    GsReportCreator,
  },
  data() {
    return {
      appSettingKey: "reports",
      // listViewMode: 1,
      reports: [],
      dialogReportCreatorVisible: false,
      awaitingTasks: [],
      getReportsInterval: null,
      templates: [],
      userLang: "en",
      initialized: false,
    };
  },
  computed: {
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.dialogReportCreatorVisible = false;
    this.initialized = false;

    // if (this.filter.createdAt.value === null) {
    //   const from = dayjs.utc().startOf('year').format("YYYY-MM-DD")
    //   const to = dayjs.utc().format("YYYY-MM-DD")
    //   this.filter.createdAt.value = [from, to].join(',')
    // }

    // set view
    if (this.$router.history.current.params.viewMode) {
      switch (this.$router.history.current.params.viewMode) {
        case "calendar":
          // this.listViewMode = 0
          // this.listViewMode = 1;
          break;
        case "list":
          // this.listViewMode = 1;
          break;
        case "create":
          // this.listViewMode = 1;
          this.openReportCreator(false);
          break;
        case "schedule":
          // this.listViewMode = 1;
          this.openReportCreator(true);
          break;
      }
    }

    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  created() {},
  methods: {
    async refreshTables() {
      this.$refs.reportTable.refreshTable();
    },
    openReportCreator(scheduled) {
      this.dialogReportCreatorVisible = true;
      setTimeout(() => {
        const initStartDate = scheduled
          ? dayjs().add(1, "hours").startOf("hour").toISOString()
          : null;
        if (this.$refs.reportCreatorComponent)
          this.$refs.reportCreatorComponent.create(initStartDate);
      }, 0);
    },
    closeReportCreator() {
      if (this.$refs.reportCreatorComponent.reportCreationStatus === 2) {
        this.refreshTables();
      }
      this.dialogReportCreatorVisible = false;
    },
    openReportScheduler() {
      this.openReportCreator(true);
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.v-event-timed {
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    color: #ffffff;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
    left: 4px;
    margin-right: 8px;
    position: relative;
  }
}
.v-data-table-header {
  text-transform: uppercase !important;
}
.iconLink .mdi-file-excel {
  color: $accent !important;
}
.iconLink .mdi-file-pdf {
  color: $red !important;
}
.iconLink .mdi-file-code {
  color: $mOrange !important;
}
</style>

<template>
  <v-container fluid>
    <v-row>
      <GsDataFilter
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </v-row>
    <!-- table -->
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <v-card>
          <GsDataTable
            ref="table"
            api=""
            endpoint="/reports"
            :appSetting="appSettingKey"
            :headers="headers"
            :sort-by="['updatedAt']"
            :sort-desc="[true]"
            :beforeCallApi="beforeCallApi"
            :afterCallApi="afterCallApi"
            @click:row="row_click"
          >
            <template v-slot:item.actions="{ item }">
              <GsActionsMenu
                :actions="actions"
                :onActivate="(actionId) => action_activate(actionId, item)"
              />
            </template>

            <template v-slot:item.createdAt="{ item }">
              <span>
                {{ item.createdAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$createdBy | empty }})
              </span>
            </template>

            <template v-slot:item.updatedAt="{ item }">
              <span>
                {{ item.updatedAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$createdBy | empty }})
              </span>
            </template>

            <template v-slot:item.report_type="{ item }">
              <span>
                <strong>{{ item.$calculated.$templateName }}</strong>
              </span>
            </template>

            <template v-slot:item.start_date="{ item }">
              <span v-show="item.inputValues.startDate">
                {{
                  item.inputValues.startDate | getDisplayDate("date") | empty
                }}
              </span>
              <span v-show="!item.inputValues.startDate">
                {{ item.createdAt | getDisplayDate("date") | empty }}
              </span>
            </template>

            <template v-slot:item.end_date="{ item }">
              <span>
                {{ item.inputValues.endDate | getDisplayDate("date") | empty }}
              </span>
            </template>

            <template v-slot:item.assets="{ item }">
              <div>{{ showAssets(item.inputValues.items) | shortenText }}</div>
            </template>

            <template v-slot:item.download="{ item }">
              <v-icon
                :color="showReportFormatColor(item.format)"
                @click="download_click(item, $event)"
              >
                {{ showReportFormat(item.format) }}
              </v-icon>
            </template>

            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} /
              {{ props.itemsLength }}
            </template>
          </GsDataTable>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const { fileSave } = require("@/utils/fileSave.js");
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import dayjs from "dayjs";

export default {
  name: "GsReportTable",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
  },
  props: {
    userLang: {
      type: String,
      default: "en",
    },
  },
  data() {
    return {
      appSettingKey: "reports",
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("createdAt"),
          value: "updatedAt",
          width: 150,
          sortable: true,
        },
        {
          text: this.$t("report_type"),
          value: "report_type",
          width: 150,
          sortable: false,
        },
        {
          text: this.$t("start_date"),
          value: "start_date",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("end_date"),
          value: "end_date",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("assets"),
          value: "assets",
          width: 200,
          sortable: false,
        },
        {
          text: this.$t("download"),
          value: "download",
          width: 50,
          sortable: false,
          align: "center",
        },
      ],
      initialized: false,
      actions: ["details", "download", "delete"],
      filters: [
        {
          field: "templateId",
          label: this.$t("report_type"),
          component: "GsTreeSelectFilter",
          params: {
            options: [],
          },
          width: "wider",
        },
        // {
        //   field: "updatedAt",
        //   label: this.$t("created_between"),
        //   component: "GsDateRangeFilter",
        //   params: {
        //     initDateFrom: dayjs
        //       .utc()
        //       .startOf("month")
        //       .add(-1, "month")
        //       .toISOString()
        //       .substring(0, 10),
        //     initDateTo: dayjs
        //       .utc()
        //       .toISOString()
        //       .substring(0, 10)
        //   },
        //   width: "wide"
        // },
        {
          field: "updatedAt",
          label: this.$t("created_between"),
          component: "GsDateRangeFilter2",
          params: {
            initDateFrom: dayjs
              .utc()
              .startOf("month")
              .add(-1, "month")
              .toISOString()
              .substring(0, 10),
            initDateTo: dayjs.utc().toISOString().substring(0, 10),
          },
          width: "long",
        },
        {
          field: "createdBy",
          component: "GsSelectFilter",
          params: {
            options: [],
          },
        },
      ],
    };
  },
  created() {
    // auto re-load of reports
    this.$EventBus.$on("reportListAPICalls", () => {
      this.refreshTable();
      this.$EventBus.$emit("timerAttach", "reportListAPICalls", 600000);
    });
    this.$EventBus.$emit("timerAttach", "reportListAPICalls", 600000);
  },
  beforeDestroy() {
    this.$EventBus.$off("reportListAPICalls");
  },
  watch: {
    filter: {
      deep: true,
      handler(value) {
        if (!this.initialized) return;
        this.$store.commit("setAppSetting", {
          parent: this.appSettingKey,
          key: "filter",
          value: this.cloneObj(value),
        });
        this.$refs.table.goToFirstPage();
      },
    },
  },
  async mounted() {
    this.initialized = false;
    if (this.$store.state.appSettings[this.appSettingKey]["filter"]) {
      this.filter = this.$store.state.appSettings[this.appSettingKey]["filter"];
    }
    await this.fillTemplateIdOptions();
    await this.fillCreatedByOptions();
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += `&filter=status:eq:report.status.success`;
      // params.url += `,ownedBy:eq:${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;

      let filterStr = this.$refs.dataFilter.getFilter();

      if (filterStr != "") {
        filterStr = "," + filterStr.substring("&filter=".length);
      }

      params.url += filterStr;
      params.url += "&r8sFields=createdBy.name";
      return params;
    },
    async afterCallApi(params) {
      const templateIds = params.items.map((p) => p.templateId);
      const templateNames = await this.getTemplateNamesByIds(templateIds);
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = item?.createdBy?.name || "";
        item.$calculated.$templateName = templateNames[item.templateId];
      }
      return params;
    },
    async download_click(item, event) {
      if (event) event.stopPropagation();

      const reportId = item.reportId;
      const format = item.format;

      try {
        const { data, headers } = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/reports/${reportId}/fileContent`,
          method: "GET",
          resType: "blob",
        });

        const fileName = headers["content-disposition"]
          ? headers["content-disposition"].match(/filename="(.*)"/)[1]
          : `${reportId}.${format}`;
        fileSave(data, fileName);
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async delete_click(item) {
      const reportId = item.reportId;
      const jobId = item.jobId;

      const confirmText = `${this.$t("delete_modal")}`;
      const confirmResult = confirm(confirmText);
      if (!confirmResult) return;

      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/reports/${reportId}`;
        const result = await callAPI({ url, method: "DELETE" });
        if (result.status !== 204) return;
        await this.deleteJob(jobId);

        await this.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    details_click(item) {
      this.$router.push_safe({
        name: "Report Details",
        params: { reportId: item.reportId },
      });
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "details":
          this.details_click(item);
          break;
        case "download":
          this.download_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    row_click(item) {
      this.details_click(item);
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async fillTemplateIdOptions() {
      const url = `/templates?fields=templateId,name,tags,format&limit=100`;
      const { data } = await callBffAPI({ url, method: "GET", cache: true });
      if (!data) return;

      const templateId_filter = this.filters.find(
        (p) => p.field === "templateId"
      );

      const items = this.getTemplateTypeItems(data);
      // console.log(items)

      templateId_filter.params.options = items;
    },
    async fillCreatedByOptions() {
      const data = await this.getUsers();
      if (!data) return;
      this.filters.find((p) => p.field === "createdBy").params.options =
        data.map((p) => ({ value: p._uri, text: p.name }));
    },
  },
};
</script>
